div.skill {
  display: inline-block;
  margin: 1rem;
  width: max-content;
  text-align: center;
  text-transform: uppercase;
}
div.skill > p {
  margin-top: 1rem;
  font-family: "Inconsolata", monospace;
}
div.skill-circle {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
  position: relative;
  background-color: #363636;
}
div.block {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0%;
  transition: height 1s;
  background-color: var(--foreground-color);
}
div.skill-circle p {
  font-size: 1.4rem;
}
