nav.menu {
  position: sticky;
  top: 0;
  background-color: var(--foreground-color);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 5px black;
}
a.title {
  padding-left: 1rem;
  align-self: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "Audiowide", cursive;
  color: var(--font-color);
  outline: none;
  text-decoration: none;
}
div.menu {
  display: flex;
  justify-content: right;
}
a.linkBt {
  position: relative;
  display: block;
  outline: none;
  color: var(--font-color);
  background-color: var(--foreground-color);
  width: 3rem;
  height: 3rem;
  transition: filter 300ms;
  font-size: 1.5rem;
}
a.linkBt svg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
a.linkBt:hover {
  filter: brightness(85%);
}
a.linkBt:active {
  filter: brightness(75%);
}
div.hamburger {
  display: none;
}

@media (max-device-width: 900px) {
  div.menu {
    flex-direction: row;
    position: absolute;
    top: -100%;
    right: 0;
    transition: top 300ms, opacity 600ms ease-out;
    z-index: -1;
    opacity: 0;
  }
  a.linkBt {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  div.hamburger {
    display: block;
    padding: 1rem;
  }
  div.hamburger:hover + div.menu,
  div.menu:hover {
    display: flex;
    top: 100%;
    opacity: 1;
  }
}
