section.header h1 {
  font-family: "Inconsolata", monospace;
}
@media (max-device-width: 900px) {
  section.header h1.title {
    width: max-content;
    font-size: 2.7rem;
  }
}
section.header svg {
  margin-top: 1rem;
}
section.header svg text {
  font-family: "Audiowide", cursive;
  text-transform: uppercase;
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  fill: var(--font-color);
  stroke: var(--font-color);
  font-size: 140px;
}
@keyframes stroke {
  0% {
    fill: var(--background-color);
    stroke: var(--font-color);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 1;
  }
  70% {
    fill: var(--background-color);
    stroke: var(--font-color);
  }
  80% {
    fill: var(--background-color);
    stroke: var(--font-color);
    stroke-width: 3;
  }
  100% {
    fill: var(--font-color);
    stroke: var(--font-color);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
