@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Comfortaa:wght@300&family=Inconsolata:wght@600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --font-color: #f0f2f0;
  --background-color: #303030;
  --background-color2: #262626;
  --foreground-color: #3955d3;
}
html {
  background-color: var(--background-color);
  color: var(--font-color);
  font-family: sans-serif;
  scroll-behavior: smooth;
}
section {
  padding: 10vh 2rem;
}
section:nth-child(2n) {
  background-color: var(--background-color2);
}
.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::selection,
::-moz-selection {
  transition: background-color 300ms;
  background-color: #80808094;
}
